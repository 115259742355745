import { DefaultSeoProps } from 'next-seo'

const manifest = require('../public/manifest.json')

const config = {
    title: manifest.name,
    description: manifest.description,
    apiUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    website: 'https://leakshaven.com',
    website_url_name: 'leakshaven.com',
    website_short_name: manifest.short_name,
    color: manifest.theme_color,
    keywords: manifest.keywords.join(', '),
    gtmId: 'GTM-TTM87TX2',
    msClarityId: 'lf5g7c4hba',
    telegramChannel: 'https://t.me/leakshaven_com',
    discordServer: 'https://discord.gg/leakshaven',
    discordUsername: 'undercover304',
    telegramLink: 'https://t.me/leakshaven_support_bot',
}

export const defaultSeoConfig: DefaultSeoProps = {
    openGraph: {
        type: 'object',
        locale: 'en_US',
        url: config.website,
        siteName: config.website_short_name,
        description: config.description,
        title: config.title,
        images: [
            {
                url: 'images/og-image.png',
                width: 521,
                height: 268,
                alt: config.title,
                type: 'image/png',
            },
        ],
    },
    twitter: {
        cardType: 'summary_large_image',
    },
    canonical: config.website,
    title: config.title,
    description: config.description,
    defaultTitle: config.title,
    additionalMetaTags: [
        {
            name: 'twitter:title',
            content: config.title,
        },
        {
            name: 'twitter:description',
            content: config.description,
        },
        {
            name: 'twitter:image:src',
            content: '/images/og-image.png',
        },
        {
            name: 'keywords',
            content: config.keywords,
        },
        {
            name: 'application-name',
            content: config.website_short_name,
        },
        {
            name: 'apple-mobile-web-store-capable',
            content: 'yes',
        },
        {
            name: 'apple-mobile-web-store-status-bar-style',
            content: 'default',
        },
        {
            name: 'apple-mobile-web-store-title',
            content: config.website_short_name,
        },
        {
            name: 'format-detection',
            content: 'telephone=no',
        },
        {
            name: 'mobile-web-store-capable',
            content: 'yes',
        },
        {
            name: 'msapplication-TileColor',
            content: config.color,
        },
        {
            name: 'msapplication-tap-highlight',
            content: 'no',
        },
        {
            name: 'theme-color',
            content: config.color,
        },
    ],
}

export default config
