import { useCounter } from '@mantine/hooks'
import { useQuery } from '@tanstack/react-query'
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { apiRefreshToken, fetchCreditsBalance } from '@/api/api'
import { indetifyFromEmail } from '@/lib/clarity'
import { BalanceRes } from '@/types/content.type'

interface TCreditContext {
    credits: number
    maskTokens: boolean
    decrement: () => void
    refresh: () => void
    creditConfig: BalanceRes
    isFetching: boolean
}

const initialCreditContext: TCreditContext = {} as TCreditContext

const CreditContext = createContext<TCreditContext>(initialCreditContext)

const initData: BalanceRes = {
    balance: 10,
    mask_credits: false,
    email: 'anonymous',
    anonymous: true,
    frame: 'HOUR',
    frameCounter: 24,
    restock: 10,
    planTitle: 'Free',
    planFree: true,
}

export const CreditProvider = ({ children }: PropsWithChildren<{}>) => {
    const [creditCount, handlers] = useCounter(3)
    const [maskTokens, setMaskTokens] = useState<boolean>(false)

    const { data, isSuccess, refetch, isFetching } = useQuery({
        queryKey: ['credits', 'balance'],
        refetchInterval: 1000 * 60 * 5,
        refetchOnWindowFocus: true,
        queryFn: () => fetchCreditsBalance(),
        initialData: initData,
    })

    const decrement = () => {
        handlers.decrement()
    }

    useEffect(() => {
        if (isSuccess && data) {
            setMaskTokens(data.mask_credits)
            handlers.set(data.balance)

            if (!data.anonymous) {
                indetifyFromEmail(data.email)
                apiRefreshToken()
            }
        }
    }, [isSuccess, data])

    return (
        <CreditContext.Provider
            value={{
                credits: creditCount,
                maskTokens,
                refresh: refetch,
                decrement,
                creditConfig: data,
                isFetching,
            }}
        >
            {isSuccess && data.planFree && <></>}
            {children}
        </CreditContext.Provider>
    )
}

export const useCredits = () => {
    const context = useContext(CreditContext)
    if (context === undefined) {
        throw new Error('useApi must be used within a ApiProvider')
    }
    return context
}
