import axios from 'axios'
import { toast } from 'sonner'

import config from '@/config'
import { BalanceRes, Content, ContentPage, ContentSorting, LoginRes } from '@/types/content.type'

interface FetchContentOptions {
    count?: number
    search?: string
    cursor?: string
    ethnicity?: string
    sorting?: ContentSorting
}

interface LoginBody {
    email: string
    password: string
}

interface VerifyBody {
    email: string
    verifyToken: string
}

interface WithCaptcha {
    recaptchaToken: string
    cloudFlareCaptcha: string
}

export interface GetUrlParams extends WithCaptcha {
    id: string
}

type RegisterBody = LoginBody & {
    code?: string
} & Omit<WithCaptcha, 'cloudFlareCaptcha'>

const authApi = axios.create({
    baseURL: config.apiUrl,
})

authApi.interceptors.response.use(
    (res) => res,
    (error) => {
        console.log(error.response)
        if (error.response?.data && error.response?.data?.message) {
            if (typeof error.response?.data?.message === 'object') {
                for (const key in error.response?.data?.message) {
                    toast.error(error.response?.data?.message[key], {
                        position: 'top-center',
                        dismissible: true,
                    })
                }
            } else {
                toast.error(error.response?.data?.message, {
                    position: 'top-center',
                    dismissible: true,
                })
            }
        }
        // if (error.response?.status === 403) {
        //     localStorage.removeItem('token')
        //     authApi.defaults.headers.common['Authorization'] = ''
        // }
        return Promise.reject(error)
    }
)

const apiSetToken = (token: string, withInfo = false) => {
    localStorage.setItem('token', token)
    authApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

    if (withInfo) {
        toast.success('Logged in successfully', {
            position: 'top-center',
            dismissible: true,
        })
    }
}

authApi.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'

typeof localStorage != 'undefined' &&
    localStorage.getItem('token') &&
    (authApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`)

export const apiLogin = async (body: LoginBody) => {
    const login = (await authApi.post<LoginRes>(`/user/login`, body)).data
    apiSetToken(login.token, true)
}

export const apiVerify = async (body: VerifyBody) => {
    const verify = (await authApi.post<LoginRes>(`/user/verify`, body)).data
    apiSetToken(verify.token, true)
}

export const withCaptchaHeaders = ({ recaptchaToken, cloudFlareCaptcha }: Partial<WithCaptcha>) => {
    if (
        recaptchaToken &&
        process.env.NEXT_PUBLIC_ENV_DEV_MODE &&
        process.env.NEXT_PUBLIC_ENV_BYPASS_CAPTCHA_HEADER
    ) {
        recaptchaToken = process.env.NEXT_PUBLIC_ENV_BYPASS_CAPTCHA_HEADER!
    }

    if (
        cloudFlareCaptcha &&
        process.env.NEXT_PUBLIC_ENV_DEV_MODE &&
        process.env.NEXT_PUBLIC_ENV_BYPASS_CAPTCHA_HEADER
    ) {
        cloudFlareCaptcha = process.env.NEXT_PUBLIC_ENV_BYPASS_CAPTCHA_HEADER!
    }

    let headers: Record<string, string> = {}
    if (recaptchaToken) headers['x-recaptcha-token'] = recaptchaToken
    if (cloudFlareCaptcha) headers['x-captcha-token'] = cloudFlareCaptcha
    return headers
}

export const fetchUrl = async ({ recaptchaToken, id, cloudFlareCaptcha }: GetUrlParams) => {
    return (
        await authApi.get<{ url: string }>(`${config.apiUrl}/content/${id}/url`, {
            headers: {
                ...withCaptchaHeaders({
                    recaptchaToken,
                    cloudFlareCaptcha,
                }),
            },
        })
    ).data
}

export const apiRegister = async (body: RegisterBody) => {
    return (
        await authApi.post<{ success: boolean }>(`/user/register`, body, {
            headers: {
                ...withCaptchaHeaders({
                    recaptchaToken: body.recaptchaToken,
                }),
            },
        })
    ).data
}

export const apiRefreshToken = async () => {
    const login = (await authApi.get<LoginRes>(`/user/refresh`)).data
    apiSetToken(login.token)
}

export const apiLogout = () => {
    localStorage.removeItem('token')
    authApi.defaults.headers.common['Authorization'] = ''

    toast.success('Logged Out!', {
        position: 'top-center',
        dismissible: true,
    })
}

export const fetchContent = async ({
    count,
    search,
    cursor,
    sorting,
    ethnicity,
}: FetchContentOptions) => {
    if (!count) count = 20
    const searchParams = new URLSearchParams()
    searchParams.append('count', count.toString())
    if (search && search != '') searchParams.append('search', search)
    if (cursor && cursor != '') searchParams.append('cursor', cursor)
    if (ethnicity) searchParams.append('ethnicity', ethnicity)
    if (sorting) searchParams.append('sorting', sorting)

    return (await authApi.get<ContentPage>(`/content?${searchParams.toString()}`)).data
}

export const fetchSimilar = async (id: string) => {
    return (await authApi.get<Content[]>(`/content/${id}/similar`)).data
}

export const fetchFull = async (id: string) => {
    return (await authApi.get<Content>(`/content/${id}/full`)).data
}

export const redeemCreditCode = async (code: string) => {
    return (await authApi.get<{ success: boolean; credits: number }>(`/credits/redeem/${code}`)).data
}

export const fetchCreditsBalance = async () => {
    const res = await authApi.get<BalanceRes>(`/credits/balance`)
    return res.data
}
