import { AdPlacementProvider } from '@/provider/ad-placement-provider'
import { CreditProvider } from '@/provider/credit-provider'
import { QueryProvider } from '@/provider/query-provider'

const ProviderWrapper = ({ children }: React.PropsWithChildren<{}>) => {
    return (
        <QueryProvider>
            <CreditProvider>
                <AdPlacementProvider> {children}</AdPlacementProvider>
            </CreditProvider>
        </QueryProvider>
    )
}

export default ProviderWrapper
