import { IconBrandDiscord, IconBrandTelegram } from '@tabler/icons-react'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Sheet, SheetContent, SheetDescription, SheetHeader } from '@/components/ui/sheet'
import { AccountCard } from '@/components/User/AccountCard'
import config from '@/config'
import useQuery from '@/provider/query-provider'

import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card'

export const CreditCard = () => {
    const { setParam, query } = useQuery()

    return (
        <Sheet
            open={!!query.credits}
            onOpenChange={() => {
                setParam('credits', undefined)
            }}
        >
            <SheetContent className='sm:max-w[26rem] w-auto overflow-x-scroll'>
                <SheetHeader className='mt-3 '>
                    <AccountCard />
                    <SheetDescription>
                        <div className=' text-lg mb-2'>Need more credits?</div>
                        <div className='flex sm:grid sm:grid-cols-2 flex-row gap-4 items-start flex-wrap'>
                            <Card className='h-full flex flex-col bg-gradient-to-br from-account-bg to-transparent flex-grow'>
                                <CardHeader className=''>
                                    <CardTitle className='flex justify-center items-center'>
                                        Premium
                                    </CardTitle>
                                </CardHeader>
                                <CardDescription className='flex justify-center items-center mb-2'>
                                    Starting at <b className='ml-1'>4,99$</b>
                                </CardDescription>
                                <CardFooter>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <Link href={`/plans`}>Get Premium</Link>
                                    </Button>
                                </CardFooter>
                            </Card>
                            <Card className='h-full flex flex-col bg-gradient-to-br from-admaven-bg to-transparent flex-grow'>
                                <CardHeader className=''>
                                    <CardTitle className='flex justify-center items-center'>
                                        <Image
                                            alt='admaven logo'
                                            width='4'
                                            height='4'
                                            src='/images/admaven.png'
                                            className='mr-2 h-5 w-5 rounded'
                                        />
                                        AdMaven
                                    </CardTitle>
                                </CardHeader>
                                <CardFooter>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <a href={`${config.apiUrl}/credits/moreAdMaven`} target='_blank'>
                                            Free <b className='mr-1 ml-1'> 4 </b> Credits
                                        </a>
                                    </Button>
                                </CardFooter>
                            </Card>
                            <Card className='h-full flex flex-col bg-gradient-to-br from-lv-accent-bg to-transparent flex-grow'>
                                <CardHeader className=''>
                                    <CardTitle className='flex justify-center items-center'>
                                        <Image
                                            alt='linkvertise logo'
                                            width='4'
                                            height='4'
                                            src='/images/linkvertise.png'
                                            className='mr-2 h-5 w-5'
                                        />
                                        Linkvertise
                                    </CardTitle>
                                    {/*<CardDescription>Complete a task on Linkvertise</CardDescription>*/}
                                </CardHeader>
                                <CardFooter>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <a href={`${config.apiUrl}/credits/more`} target='_blank'>
                                            Free <b className='mr-1 ml-1'> 3 </b> Credits
                                        </a>
                                    </Button>
                                </CardFooter>
                            </Card>
                            <Card className='h-full flex flex-col bg-gradient-to-br from-work-ink-bg to-transparent flex-grow'>
                                <CardHeader className=' '>
                                    <CardTitle className='flex justify-center items-center'>
                                        <Image
                                            alt='workink logo'
                                            width='4'
                                            height='4'
                                            src='/images/work-ink.png'
                                            className='mr-2 h-5 w-5'
                                        />
                                        Work.ink
                                    </CardTitle>
                                </CardHeader>
                                <CardFooter>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <a href={`${config.apiUrl}/credits/moreWorkInk`} target='_blank'>
                                            Free <b className='mr-1 ml-1'> 2 </b> Credits
                                        </a>
                                    </Button>
                                </CardFooter>
                            </Card>
                        </div>
                        <div className='flex mt-2'>
                            <Card className='flex flex-grow flex-col'>
                                <CardHeader className=''>
                                    <CardTitle className=''>Contact Us!</CardTitle>
                                    <CardDescription>
                                        If you want to advertise for us, you can <b>get credits</b> or
                                        even get a <b>Lifetime Premium</b> Plan!
                                        <Separator className='mt-1 mb-1' />
                                        State your offer and we will get back to you as soon as possible.
                                    </CardDescription>
                                </CardHeader>
                                <CardFooter className='flex items-center  p-2  space-x-2 '>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <a href={`${config.discordServer}`} target='_blank'>
                                            <IconBrandDiscord className='mr-2 h-4 w-4' />
                                            Discord
                                        </a>
                                    </Button>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <a href={`${config.telegramLink}`} target='_blank'>
                                            <IconBrandTelegram className='mr-2 h-4 w-4' />
                                            Telegram
                                        </a>
                                    </Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </SheetDescription>
                </SheetHeader>
            </SheetContent>
        </Sheet>
    )
}

export default CreditCard
