import { IconBrandTelegram, IconPaywall } from '@tabler/icons-react'
import { BadgeDollarSign } from 'lucide-react'
import * as React from 'react'

import { FakeCardProps } from '@/components/Content/FakeCard'

export const fakeCardsData: FakeCardProps[] = [
    {
        isAdCard: true,
        size: '92 GB',
        videos: 80,
        title: 'Heaven POV',
        text: 'Get the most requested archive ever!',
        Icon: <IconBrandTelegram className='' />,
        image: 'https://s3.leakshaven.com/static/havenpov.jpg',
        link: 'https://t.me/heavenpovv',
        campaign: 'heavenpov',
        rate: 0.5,
    },
    {
        isAdCard: true,
        // title: 'Premium for 4.99$',
        // text: 'Not enough credits? Get more out of Leakshaven with Premium!',
        Icon: <IconPaywall className='' />,
        DescriptionComponent: (
            <div className=' flex items-center justify-center flex-grow flex-col '>
                <div className='  text-muted-foreground flex flex-row items-end justify-between'>
                    <span className='text-4xl font-bold flex items-center text-amber-300'>
                        50
                        <BadgeDollarSign className='ml-1 w-8 h-8 mt-2' />
                    </span>
                    <div> / day</div>
                    {/*<span className='text-2xl font-bold'>4.99$</span> / month*/}
                    {/*<ArrowRight className='text-white text-xs bg-primary primary-shadow rounded-full mr-2 p-1 ml-2' />*/}
                    {/*<span className='text-2xl font-bold flex items-center'>*/}
                    {/*    50*/}
                    {/*    <BadgeDollarSign className='ml-1 w-7 h-7 mt-2' />*/}
                    {/*</span>*/}
                    {/*<div> / day</div>*/}
                </div>
                <div className=' text-muted-foreground text-xl mt-2'>
                    Premium for <b>4.99$</b> / month
                </div>
            </div>
        ),
        image: 'https://s3.leakshaven.com/static/logo-smoke2.webp',
        backDropImage: 'https://s3.leakshaven.com/static/animated-logo-still.jpg',
        link: '/plans',
        campaign: 'premium',
        callToAction: 'Get Premium',
        callToActionStyle: 'bg-primary transition-all animate-bounce',
        rate: 1,
    },
]
